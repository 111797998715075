import { computed } from "vue";
import { useChordsStore } from "../../stores/chordsStore";
import GuitarChordEditor from "@/components/GuitarChordEditor/GuitarChordEditor.vue";
export default {
  name: "ChordEditDialog",
  props: ["dialog", "editedItem"],
  components: { GuitarChordEditor },
  setup(props) {
    const chordsStore = useChordsStore();

    const dialog = computed(() => {
      return props.dialog;
    });

    const editedItem = computed(() => {
      return props.editedItem;
    });

    function deleteChanges() {
      const confirm = window.confirm("Möchten Sie wirklich abbrechen?");
      if (confirm) this.$emit("closeDialog");
    }

    async function saveChord(close) {
      if (!editedItem.value._id) await chordsStore.createChord(editedItem.value);
      else await chordsStore.updateChord(editedItem);
      if (close) this.$emit("closeDialog");
    }

    function sendChordInformation(chord) {
      editedItem.value.chord = chord;
    }

    return { dialog, editedItem, saveChord, deleteChanges, sendChordInformation };
  },
};
