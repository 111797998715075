import "vuetify/styles";
import "@mdi/font/css/materialdesignicons.css";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as labs from "vuetify/labs/components";
import * as directives from "vuetify/directives";

const defaultTheme = {
  dark: false,
  colors: {
    background: "#121212",
    surface: "#fff",
    primary: "#b25b6e",
    "primary-darken-1": "#7c3042",
    secondary: "#f9cdd4",
    "secondary-darken-1": "#eca1ac",
    error: "#B00020",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FB8C00",
  },
};

const pinkLightTheme = {
  dark: false,
  colors: {
    background: "#121212",
    surface: "#fff",
    primary: "#b25b6e",
    "primary-darken-1": "#7c3042",
    secondary: "#f28cb1",
    "secondary-darken-1": "#eca1ac",
    error: "#B00020",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FB8C00",
  },
};

const pinkDarkTheme = {
  dark: true,
  colors: {
    background: "#121212",
    surface: "#1E1E1E",
    primary: "#b25b6e",
    "primary-darken-1": "#7c3042",
    secondary: "#f9cdd4",
    "secondary-darken-1": "#eca1ac",
    error: "#B00020",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FB8C00",
  },
};

const orangeTheme = {
  dark: true,
  colors: {
    background: "#121212",
    surface: "#1E1E1E",
    primary: "rgb(197,123,75)",
    "primary-darken-1": "#6f4104",
    secondary: "#b0911a",
    "secondary-darken-1": "#b3af0f",
    error: "#B00020",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FB8C00",
  },
};

const blueTheme = {
  dark: true,
  colors: {
    background: "#121212",
    surface: "#1E1E1E",
    primary: "#082876",
    "primary-darken-1": "#040864",
    secondary: "#14d1a7",
    "secondary-darken-1": "#096f35",
    error: "#B00020",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FB8C00",
  },
};

export const vuetify = createVuetify({
  components: {
    ...labs,
    ...components,
  },
  directives,
  theme: {
    defaultTheme: "pinkDarkTheme",
    themes: {
      defaultTheme,
      pinkLightTheme,
      pinkDarkTheme,
      orangeTheme,
      blueTheme,
    },
  },
});
