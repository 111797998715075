import { computed, ref } from "vue";
import { useUserStore } from "@/stores/userStore";
import { useTheme } from "vuetify";

Array.prototype.asyncForEach = async function (callback) {
  for (let index = 0; index < this.length; index++) {
    await callback(this[index], index, this);
  }
};

export default {
  name: "UserEditDialog",
  props: ["dialog"],
  emits: ["saveUser"],
  setup(props, ctx) {
    const userStore = useUserStore();
    const theme = useTheme();
    const themes = [
      { value: "defaultTheme", title: "Default" },
      { value: "blueTheme", title: "Blau" },
      { value: "orangeTheme", title: "Orange" },
    ];
    const newTheme = ref(null);

    const user = computed(() => {
      return userStore.user;
    });

    const avatar = userStore.getUserAvatarUrl;

    const image = ref(null);

    const rules = [
      (value) => {
        return (
          !value ||
          !value.length ||
          value[0].size < 2000000 ||
          "Avatar size should be less than 2 MB!"
        );
      },
    ];
    const dialog = computed(() => {
      return props.dialog;
    });

    async function upload() {
      const files = [image.value];
      let formData = new FormData();
      await files.asyncForEach(async (file) => {
        formData.append("file", file, file.name);
        await userStore.uploadUserAvatar(formData);
      });
    }

    function changeTheme() {
      theme.global.name.value = newTheme.value;
      localStorage.setItem("theme", theme.global.name.value);
    }

    async function saveUser(close = false) {
      if (newTheme.value && newTheme.value !== user.value.theme) user.value.theme = newTheme.value;
      await ctx.emit("saveUser", close);
    }

    return { dialog, user, rules, image, themes, newTheme, upload, avatar, changeTheme, saveUser };
  },
};
