import { useSheetStore } from "@/stores/sheetStore";
import { useChordsStore } from "@/stores/chordsStore";
import { computed, ref } from "vue";
import { jsPDF } from "jspdf";
import GuitarChord from "@/components/GuitarChord.vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { getTabTemplate } from "../../helpers/helpers";
import GuitarChordEditor from "@/components/GuitarChordEditor/GuitarChordEditor.vue";
import { useDisplay } from "vuetify";

export default {
  name: "SheetEditDialog",
  props: ["dialog", "editedItem", "allChords"],
  components: { GuitarChord, ClassicEditor, GuitarChordEditor },
  setup(props) {
    const dialog = computed(() => {
      return props.dialog;
    });
    const chordSearchDialog = ref(false);
    const search = ref("");
    const searchChord = ref({ nutPosition: 0, chord: [{}], tuning: "EADGBe", name: "" });
    const foundChords = ref([]);
    const selectedChords = ref([]);

    const { mobile } = useDisplay();

    const addedTemplate = ref(false);

    const defaultTab = computed(() => {
      return getTabTemplate();
    });

    const difficultyItems = [
      { title: "Keine Angabe", value: "" },
      { title: "Anfänger", value: "beginner" },
      { title: "Fortgeschrittener", value: "intermediate" },
      { title: "Profi", value: "pro" },
    ];

    const song = computed(() => {
      return props.editedItem;
    });

    const chordStore = useChordsStore();
    const sheetStore = useSheetStore();
    const preview = ref(true);
    const tabEditor = ref(false);

    const previewSongText = computed(() => {
      return getPdfHtml();
    });

    function translateSongtext(text) {
      const openChordRegex = /\[/g;
      const closingChordRegex = /\]/g;
      const openCommentRegex = /\{/g;
      const closingCommentRegex = /\}/g;

      if (text) {
        text = replaceTextLines(text);
        return text
          .replace(openChordRegex, '<span class="chord">')
          .replace(closingChordRegex, "</span>")
          .replace(openCommentRegex, '<span class="comment">')
          .replace(closingCommentRegex, "</span>");
      }
      return "";
    }

    function getDifficulty(difficulty) {
      switch (difficulty) {
        case "beginner":
          return "Anfänger";
        case "intermediate":
          return "Fortgeschrittener";
        case "pro":
          return "Profi";
        default:
          return "Keine Angabe";
      }
    }

    function replaceTextLines(text) {
      let splittedTags = text.split("</p>");
      splittedTags = splittedTags.map((tag) => {
        if (tag.includes("["))
          tag = tag.replace("<p>", `<p class='chordLine' style="font-size: 14px">`);
        else
          tag = tag.replace(
            "<p>",
            `<p class='textLine' style="color: black; font-size: 18px!important">`,
          );
        return tag;
      });
      return splittedTags.join("</p>");
    }

    function getSheetHeader() {
      let headerHtml = `<h1 style="display: flex; justify-content: center; color: black">${song.value.songTitle}</h1>`;
      headerHtml += `<p style="display: flex; justify-content: center; color: black">${song.value.songWriter}</p>`;
      headerHtml += `<span style="display: flex; justify-content: center; margin-bottom: 10px; color: black">`;
      headerHtml += `<p><b>Key: </b> ${song.value.songKey} <b>BPM: </b> ${song.value.songSpeed} `;
      if (song.value.capo > 0) headerHtml += `<b>Capo: </b> ${song.value.capo}. Bund `;
      if (song.value.difficulty !== "")
        headerHtml += `<b>Schwierigkeit: </b> ${getDifficulty(song.value.difficulty)}`;
      headerHtml += `</p><br/></span> `;
      return headerHtml;
    }

    function getPdfHtml() {
      const header = getSheetHeader();
      const translatedText = translateSongtext(song.value.songText);
      return `<body>
          ${header}
          ${translatedText}
        </body>`;
    }

    function createPDF(saveDoc) {
      const doc = jsPDF();
      doc.html(getPdfHtml(), {
        callback: function (doc) {
          if (saveDoc) doc.save(`${song.value.songTitle}.pdf`);
        },
        autoPaging: "text",
        x: 15,
        y: 15,
        width: 180, //target width in the PDF document
        windowWidth: 650, //window width in CSS pixels
      });
      if (!saveDoc) return doc;
    }

    function downloadPdf() {
      createPDF(true);
    }

    async function uploadPdf() {
      await sheetStore.createPdfForUpload(getPdfHtml(), song.value);
    }

    function getChordData(chord) {
      return props.allChords.find((c) => c._id === chord);
    }

    function addTabTemplate() {
      if (addedTemplate.value || song.value.tab?.length) {
        const confirm = window.confirm(
          "Template oder Tab bereits vorhanden. Wirklich zusätzlich hinzufügen?",
        );
        if (confirm) song.value.tab += defaultTab.value;
      } else {
        song.value.tab += defaultTab.value;
        addedTemplate.value = true;
      }
    }

    async function sendChordInformation(chords) {
      searchChord.value.chord = chords
        .flat()
        .filter((item) => typeof item === "object" && !Array.isArray(item));

      if (searchChord.value.nutPosition > 0) {
        searchChord.value.chord = searchChord.value.chord.map((chord) => ({
          ...chord,
          fret: parseInt(chord.fret) + (searchChord.value.nutPosition - 1),
        }));
      }
      await searchChords();
    }

    async function searchChords() {
      foundChords.value = await chordStore.searchChords({
        search: search.value,
        chords: JSON.stringify(searchChord.value.chord),
      });
    }

    function addChord(chordId) {
      if (!selectedChords.value?.includes(chordId)) {
        selectedChords.value.push(chordId);
      } else {
        selectedChords.value = selectedChords.value.filter((chord) => chord !== chordId);
      }
    }

    function addChords() {
      selectedChords.value.forEach((chord) => {
        if (!song.value.chords.includes(chord)) {
          song.value.chords.push(chord);
        }
      });

      selectedChords.value = [];
      search.value = "";
      foundChords.value = [];
      searchChord.value.chord = [{}];
      chordSearchDialog.value = false;
    }

    function cancelSearchChords() {
      selectedChords.value = [];
      search.value = "";
      foundChords.value = [];
      searchChord.value.chord = [{}];
      chordSearchDialog.value = false;
    }

    return {
      dialog,
      song,
      preview,
      previewSongText,
      difficultyItems,
      tabEditor,
      defaultTab,
      search,
      searchChord,
      foundChords,
      editor: ClassicEditor,
      chordSearchDialog,
      selectedChords,
      mobile,
      addChord,
      addChords,
      cancelSearchChords,
      downloadPdf,
      uploadPdf,
      getChordData,
      addTabTemplate,
      searchChords,
      sendChordInformation,
    };
  },
};
