import { onMounted, computed, ref } from "vue";
import { useSheetStore } from "@/stores/sheetStore.js";
import SheetTable from "@/components/SheetTable/SheetTable.vue";
import SheetEditDialog from "@/components/SheetEditDialog/SheetEditDialog.vue";
import { useUserStore } from "@/stores/userStore";
import { useChordsStore } from "@/stores/chordsStore";

export default {
  name: "SheetsList",
  components: { SheetTable, SheetEditDialog },
  props: ["id"],
  setup(props) {
    const sheetStore = useSheetStore();
    const userStore = useUserStore();
    const chordStore = useChordsStore();
    const dialog = ref(false);
    const editedItem = ref({});

    const sheetId = computed(() => {
      return props.id;
    });

    const sheets = computed(() => {
      return sheetStore.sheets;
    });

    const allChords = computed(() => {
      return chordStore.chords;
    });

    onMounted(async () => {
      await sheetStore.getSheets();
      await chordStore.fetchChords();
      userStore.checkLogin();

      checkForSheetToOpen();
    });

    function checkForSheetToOpen() {
      if (sheetId.value) {
        const foundSheet = sheets.value?.find((sheet) => sheet._id === sheetId.value);
        if (!foundSheet?._id) return;
        editedItem.value = foundSheet;
        dialog.value = true;
      }
    }

    function createSheet() {
      editedItem.value = {
        songSpeed: 120,
        songKey: "G",
        songWriter: "",
        songTitle: "",
        difficulty: "",
        songText: "",
        capo: 0,
      };
      dialog.value = true;
    }

    function editItem(item) {
      editedItem.value = item;
      dialog.value = true;
    }

    async function saveSheet(close) {
      if (!editedItem.value._id) {
        const createdSheet = await sheetStore.createSheet(editedItem.value);
        if (createdSheet?._id) editedItem.value = createdSheet;
      } else await sheetStore.updateSongSheet(editedItem.value);
      if (close) dialog.value = false;
      const url = new URL(window.location.href);
      if (url.href.includes(editedItem.value._id)) window.location.href = "/sheetsList";
    }

    function cancelEditing() {
      const confirm = window.confirm("Wollen Sie wirklich abbrechen?");
      if (confirm) {
        const url = new URL(window.location.href);
        if (url.href.includes(editedItem.value._id)) window.location.href = "/sheetsList";
        editedItem.value = {};
        dialog.value = false;
      }
    }

    return {
      sheets,
      dialog,
      editedItem,
      allChords,
      createSheet,
      saveSheet,
      editItem,
      cancelEditing,
    };
  },
};
