<template>
  <div>
    <base-chord
      :name="name"
      :tuning="tuning"
      :nut-position="nutPosition"
      ref="svg"
      tabindex="0"
      @keyup="onKeyUp"
    >
      <g v-for="i in [0, 1, 2, 3, 4, 5]" :key="i" class="string-group">
        <string
          @click="stringClicked(i, $event)"
          :i="i"
          :class="{ selected: selected && selected.string === i }"
        ></string>
        <fretted-note
          v-for="note in frettedNotes[i]"
          :key="[note.fret, note.string]"
          :string="note.string"
          :fret="note.fret"
          :finger="note.finger"
          class="fretted-note"
          :class="[{ selected: selected === note }, 'fretted-note']"
          @click="noteClicked(note)"
        >
        </fretted-note>
      </g>
    </base-chord>

    <v-btn class="ml-3 mt-3" color="primary" @click="$emit('sendChordInformation', frettedNotes)"
      >Akkord erhalten</v-btn
    >
  </div>
</template>
<script src="./GuitarChordEditor.js"></script>
<!----<script>
import { ref, reactive, onMounted, watch } from 'vue'
import BaseChord from '../BaseChord.vue'
import Fretboard from '../Fretboard.vue'
import FrettedNote from '../FrettedNote.vue'
import String from '../String.vue'

let svg
let svgPoint
const START_Y = 20
const FRET_HEIGHT = 20 * 0.8

export default {
  components: {
    BaseChord,
    // eslint-disable-next-line vue/no-unused-components
    Fretboard,
    FrettedNote,
    String
  },
  props: ['chord', 'chordName', 'nutPos'],
  setup(props, { emit }) {
    const name = ref('X Akkord')
    const tuning = ref('EADGBe')
    const nutPosition = ref(0)
    const svg = ref(null)

    const frettedNotes = reactive([[], [], [], [], [], []])

    const selected = ref(null)

    const onKeyUp = (e) => {
      const numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
      if (e.key === 'Backspace' || e.key === 'Delete') remove()
      if (numbers.includes(e.key)) setFinger(e)
      /*
      @keyup.up="moveFret(-1)"
      @keyup.down="moveFret(1)"
      */
    }

    const sendChordInformation = () => {
      const jsonOutput = [].concat(...frettedNotes)
      emit('sendChordInformation', JSON.parse(JSON.stringify(jsonOutput)))
    }

    onMounted(() => {
      svg.value = svg.value.$el
      svgPoint = svg.value.createSVGPoint()
      const parsedChord = JSON.parse(JSON.stringify(props.chord))
      frettedNotes.value = [
        getValue(parsedChord, 0),
        getValue(parsedChord, 1),
        getValue(parsedChord, 2),
        getValue(parsedChord, 3),
        getValue(parsedChord, 4),
        getValue(parsedChord, 5)
      ]
      if (props.chordName) name.value = props.chordName
      if (props.nutPos) nutPosition.value = props.nutPos
    })

    watch(
      frettedNotes,
      (newNotes) => {
        const map = {}
        for (const notes of newNotes) {
          for (const [i, note] of notes.entries()) {
            const key = [note.fret, note.finger]
            const other = map[key]
            if (other && isNeighbour(note, other)) {
              other.string = uniqueArray(other.string, note.string)
              notes.splice(i, 1)
              selected.value = other
            } else {
              // Store key and reference to string array
              map[key] = note
            }
          }
        }
      },
      { deep: true }
    )

    function fretFromClick(event) {
      svgPoint.x = event.clientX
      svgPoint.y = event.clientY

      let inversePt = svg.value.getScreenCTM().inverse()
      let svgClick = svgPoint.matrixTransform(inversePt)
      return Math.floor(1 + (svgClick.y - START_Y) / FRET_HEIGHT)
    }

    function findSimpleNote(notes) {
      return notes.find((x) => !Array.isArray(x.string))
    }

    function findBarredNote(notes) {
      return notes.find((x) => Array.isArray(x.string))
    }

    function stringClicked(clickedString, event) {
      let clickedFret = fretFromClick(event)
      let notes = frettedNotes[clickedString]
      let simpleNote = findSimpleNote(notes)
      let barredNote = findBarredNote(notes)
      if (!notes.length || (barredNote && barredNote.fret < clickedFret)) {
        let newNote = {
          string: clickedString,
          fret: clickedFret,
          finger: 1
        }
        notes.splice(clickedFret, 0, newNote)
        selected.value = newNote
      } else {
        simpleNote['fret'] = clickedFret
        selected.value = simpleNote
      }
    }

    function remove() {
      if (!selected.value) {
        return
      }
      let string = Array.isArray(selected.value.string)
        ? selected.value.string[0]
        : selected.value.string
      let notes = frettedNotes[string]
      let updatedNotes = notes.filter((x) => x !== selected.value)
      frettedNotes[string] = updatedNotes
      selected.value = null
    }

    function setFinger(event) {
      if (!selected.value) {
        return
      }
      selected.value['finger'] = event.key
    }

    return {
      name,
      tuning,
      nutPosition,
      frettedNotes,

      selected,
      svg,
      stringClicked,
      onKeyUp,
      sendChordInformation
    }
  }
}

function uniqueArray(...items) {
  return Array.from(new Set([].concat(...items).sort()))
}

function isNeighbour(noteA, noteB) {
  const A = [].concat(noteA.string)
  const B = [].concat(noteB.string)
  let result = false
  for (const a of A) {
    for (const b of B) {
      if (Math.abs(a - b) === 1) {
        result = true
        break
      }
    }
  }
  return result
}

function getValue(chords, index) {
  let returnVal = null
  if (chords?.length) {
    chords.forEach((chord) => {
      if (chord?.string === index) returnVal = [chord]
      if (Array.isArray(chord?.string) && chord?.string.includes(index)) returnVal = [chord]
    })
  }
  if (returnVal !== null) return returnVal
  else return []
}
</script>

<style>
.string-group:hover .string,
.string-group:hover .fretted-note circle {
  stroke-width: 2;
  stroke-opacity: 0.5;
  fill-opacity: 0.7;
}

.string-group .string.selected,
.string-group .fretted-note.selected circle,
.string-group .fretted-note.selected rect {
  stroke-width: 2;
  stroke-opacity: 0.5;
  fill-opacity: 0.7;
  fill: blue;
  stroke: blue;
}

.string-group .fretted-note.selected rect {
  fill-opacity: 1;
}
</style>
-->
